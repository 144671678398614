import { DrcSecureGroupRoute } from '@driscollsinc/driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import {
    addInfo,
    hideCommonDialogAction,
    hideLoadingScreenAction,
    serviceWorkerUpdate,
    setCommonDialogAction,
    setPageTitleAction,
    showLoadingScreenAction,
    showReportBackLinkAction,
    showToast
} from '../actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized, setUserLang } from '../actions/MasterActions';
import Dashboard from '../pages/v2/Dashboard/Dashboard';

const allGroups = [...window.config.OKTA_GGS_ADMIN_GROUPS, ...window.config.OKTA_USER_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];

var styles = (theme) => ({
    drawerIcon: {
        width: 20,
        height: 20
    },
    legendClassName: {
        fontFamily: 'Rubik'
    }
});

class V2Routes extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Switch>
                <DrcSecureGroupRoute path="/Dashboard/" exact component={Dashboard} groupsAllowed={allGroups} />
            </Switch>
        );
    }
}

function mapStateToProps(state) {
    return {
        showReportBackLink: state.rootReducer.showReportBackLink,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        userLang: state.masterReducer.userLang,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showReportBackLinkAction: () => dispatch(showReportBackLinkAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(V2Routes)));
