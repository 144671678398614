import { DrcSecureGroupRoute } from '@driscollsinc/driscolls-react-components';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import {
    addInfo,
    hideCommonDialogAction,
    hideLoadingScreenAction,
    serviceWorkerUpdate,
    setCommonDialogAction,
    setPageTitleAction,
    showLoadingScreenAction,
    showReportBackLinkAction,
    showToast
} from '../actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized, setUserLang } from '../actions/MasterActions';
import ConversionRate from '../pages/DataSetup/ConversionRate/ConversionRate';
import CustomerCodeCategory from '../pages/DataSetup/CustomerCodeCategory/CustomerCodeCategory';
import GrowerCategory from '../pages/DataSetup/GrowerCategory/GrowerCategory';
import GrowerFixedPrice from '../pages/DataSetup/GrowerFixedPrice/GrowerFixedPrice';
import GrowerPercentage from '../pages/DataSetup/GrowerPercentage/GrowerPercentage';
import ImportSettlementRate from '../pages/DataSetup/ImportSettlementRate/ImportSettelementRate';
import ItemShippingRates from '../pages/DataSetup/ItemShippingRates';
import ItemUomConversion from '../pages/DataSetup/ItemUomConversion/ItemUomConversion';
import JournalAccount from '../pages/DataSetup/JournalAccount/JournalAccount';
import LookUp from '../pages/DataSetup/LookUp/LookUp';
import PoolWeek from '../pages/DataSetup/PoolWeek/PoolWeek';
import RepackagingSkuMapping from '../pages/DataSetup/RepackagingSkuMapping/RepackagingSkuMapping';
import SettlementRate from '../pages/DataSetup/SettlementRate/SettlementRate';
import VatRateMaintenance from '../pages/DataSetup/VatRateMaintenance/VatRateMaintenance';
import PricingPool from '../pages/PricingPool';
import Reports from '../pages/Reports/Reports';
import SettlementWorkbenchDetails from '../pages/SettlementWorkbench/SettlementWorkbenchDetails';
import WeeklySettlementStatus from '../pages/WeeklySettlementStatus';
import { userBusinessUnit } from '../utils/helper';

const allGroups = [...window.config.OKTA_GGS_ADMIN_GROUPS, ...window.config.OKTA_USER_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];
class DOCRoutes extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const isDocUser = userBusinessUnit() === 'DOC';
        return (
            <Switch>
                <DrcSecureGroupRoute path="/WeeklySettlementStatus/" exact component={WeeklySettlementStatus} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/PricingPool/" exact component={PricingPool} groupsAllowed={isDocUser ? [] : allGroups} />
                <DrcSecureGroupRoute path="/SettlementWorkbench/" exact component={WeeklySettlementStatus} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/Reports/" exact component={Reports} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/PoolWeek" exact component={PoolWeek} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/LookUp" exact component={LookUp} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/ItemUomConversion" exact component={ItemUomConversion} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/ItemShippingRates" exact component={ItemShippingRates} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/SettlementRate" exact component={SettlementRate} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/RepackagingSkuMapping" exact component={RepackagingSkuMapping} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/GrowerCategory" exact component={GrowerCategory} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/GrowerFixedPrice" exact component={GrowerFixedPrice} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/CustomerCodeCategory" exact component={CustomerCodeCategory} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/VatRateMaintenance" exact component={VatRateMaintenance} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/GrowerPercentage" exact component={GrowerPercentage} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/ConversionRate" exact component={ConversionRate} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/ImportSettlementRate" exact component={ImportSettlementRate} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute path="/JournalAccount" exact component={JournalAccount} groupsAllowed={allGroups} />
                <DrcSecureGroupRoute
                    path="/SettlementWorkbench/:poolWeek/Details"
                    exact
                    component={SettlementWorkbenchDetails}
                    groupsAllowed={allGroups}
                />
            </Switch>
        );
    }
}

function mapStateToProps(state) {
    return {
        showReportBackLink: state.rootReducer.showReportBackLink,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        userLang: state.masterReducer.userLang,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showReportBackLinkAction: () => dispatch(showReportBackLinkAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(DOCRoutes));
