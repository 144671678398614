import { BusinessRulesEntryPoint } from '@driscollsinc/ggs-business-rules-module-ui';
import { withOktaAuth } from '@okta/okta-react';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPageTitleAction } from '../../../actions/actions';
import { setSettlementWorkbenchData } from '../../../actions/settlementWorkbenchAction';
import DrcWrapper from '../../../components/DrcWrapper';

function Dashboard(props) {
    return (
        <DrcWrapper>
            <BusinessRulesEntryPoint />
        </DrcWrapper>
    );
}

const mapStateToProps = ({ settlementWorkbenchReducer, rootReducer }) => ({
    total: settlementWorkbenchReducer.totalRecords,
    pageTitle: rootReducer.pageTitle,
    records: settlementWorkbenchReducer.records
});

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setSettlementWorkbenchData: (data) => dispatch(setSettlementWorkbenchData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(Dashboard)));
